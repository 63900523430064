<template>
  <div v-if="traineeRole">

    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
      <h4 id="top">Trainee Role: <small>{{ traineeRole.title }}</small></h4>
    </div>
    <div class="card">
      <div class="rainbow-background"></div>
      <table  class="card-table table">
        <tbody class="text-left">
        <tr>
          <th scope="col">ID</th>
          <td>{{ traineeRole.id }}</td>
        </tr>
        <tr>
          <th scope="col">Title</th>
          <td>{{ traineeRole.title }}</td>
        </tr>
        <tr>
          <th scope="col">Created At</th>
          <td>{{ new Date(traineeRole.created_at).toDateString() }}, {{ new Date(traineeRole.created_at).toLocaleTimeString() }}</td>
        </tr>
        <tr>
          <th scope="col">Updated At</th>
          <td>{{ new Date(traineeRole.updated_at).toDateString() }}, {{ new Date(traineeRole.updated_at).toLocaleTimeString() }}</td>
        </tr>
        </tbody>
      </table>
      <div class="card-footer">
        <div class="btn-group float-right">
          <router-link :to="{ name: 'TraineeRoleIndex' }" tag="button" class="btn btn-secondary">Back</router-link>
          <router-link :to="{ name: 'TraineeRoleEdit' }" tag="button" class="btn btn-primary">Edit</router-link>
          <button v-on:click="$refs.traineeRoleDeleteModal.show()" type="button" class="btn btn-danger">Delete</button>
        </div>
      </div>
    </div>

    <div v-if="traineeRole.trainees" class="card">
      <h1>Users Associated with this Role</h1>
      <table class="card-table table">
        <thead>
        <tr>
          <th>User</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="user in traineeRole.trainees" :key="user.id">
          <td>{{ user.name }}</td>
          <td><router-link :to="{ name: 'UserShow', params: { id: user.id } }" tag="button" class="btn btn-secondary">Show</router-link></td>
        </tr>
        <tr>
          <td><b>TOTAL:</b> {{ traineeRole.trainees.length }}</td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>

    <!-- Modal Component -->
    <b-modal ref="traineeRoleDeleteModal" header-bg-variant="danger" header-text-variant="light" title="Confirm Delete" ok-variant="danger" ok-title="Delete" @ok="deleteTraineeRole" centered>
      <p>Are you sure you want to delete this User Role?</p>
    </b-modal>

  </div>
  <loading-overlay v-else></loading-overlay>
</template>

<script>
import { TraineeRoleService } from '@/common/services/trainee_role.service';
import LoadingOverlay from '@/common/components/loading-overlay.vue';

export default {
  name: 'TraineeRoleShow',
  components: {
    LoadingOverlay,
  },
  data() {
    return {
      traineeRole: null,
    };
  },
  created() {
    this.getTraineeRole();
  },
  methods: {
    getTraineeRole() {
      TraineeRoleService.get(this.$route.params.id)
        .then((response) => {
          this.traineeRole = response.data;
        });
    },
    deleteTraineeRole() {
      TraineeRoleService.destroy(this.$route.params.id)
        .then((response) => {
          this.$router.push({ name: 'TraineeRoleIndex' });
          this.$notify({
            title: `Deleted ${response.data.title}`,
            text: 'Successfully deleted User Role.',
            type: 'success',
            width: 350,
          });
        });
    },
  },
};

</script>

<style lang="scss" scoped>
  @import "../../styles/views/trainee_roles";
</style>
